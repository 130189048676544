import { useState } from 'react'
import ModalContainer from '../../shared/ModalContainer'
import CreateGroupForm from './CreateGroupForm'
import EditGroupForm from './EditGroupForm'
import {
  Box,
  Stack,
  Button,
  FormControl,
  FormLabel,
  FormHelperText,
  useDisclosure,
  Grid,
  GridItem,
  Text,
  Divider,
  Container,
} from '@chakra-ui/react'
import { StoreData } from '@flowby/shared-firebase'
import { QueueData } from '@flowby/shared-firebase'
import { GroupData } from '@flowby/shared-firebase'

export default function Groups({
  queuesData,
  groupsData,
  store,
}: {
  storeData: StoreData
  queuesData: QueueData[]
  groupsData: GroupData[]
  store: string
}) {
  const editGroupDisclosure = useDisclosure()
  const [editGroup, setEditGroup] = useState<string | undefined>(undefined)
  const createGroupDisclosure = useDisclosure()

  const onFinishCreateGroup = (group: string) => {
    createGroupDisclosure.onClose()
    setEditGroup(group)
    editGroupDisclosure.onOpen()
  }

  return (
    <Stack spacing={4} pb={2}>
      <ModalContainer
        isOpen={createGroupDisclosure.isOpen}
        onClose={createGroupDisclosure.onClose}
        header="Create new queue group"
        content={<CreateGroupForm store={store} groupsData={groupsData} onFinish={onFinishCreateGroup} />}
      />
      <ModalContainer
        footer={
          <Container pb={2}>
            <Divider />
            <Button size="md" mt={2} w="100%" variant="outline" onClick={editGroupDisclosure.onClose}>
              Close
            </Button>
          </Container>
        }
        isOpen={editGroupDisclosure.isOpen}
        onClose={editGroupDisclosure.onClose}
        header="Edit queue group"
        content={
          editGroup ? <EditGroupForm
            store={store}
            group={editGroup}
            queuesData={queuesData}
            groupsData={groupsData}
            onFinish={editGroupDisclosure.onClose}
          /> : <Text>Something went wrong.</Text>
        }
      />
      <FormControl id="groups">
        <FormLabel>Queue Groups</FormLabel>
        <FormHelperText>
          Queue groups lets you organize your queues and create a joint QR code your customers can use to select to get
          in line of one of the queues in the group.
        </FormHelperText>
        <Stack spacing={1} py={1}>
          {groupsData &&
            groupsData.map(group => (
              <Box key={group.shortName}>
                <Grid
                  templateColumns="repeat(6, 1fr)"
                  py={2}
                  px={5}
                  borderWidth="1px"
                  borderRadius="0.375rem"
                  _hover={{ textDecoration: 'none' }}
                >
                  <GridItem colSpan={5}>
                    <Text size="lg">{group.displayName}</Text>
                  </GridItem>
                  <GridItem display="flex" colSpan={1} justifyContent="center" alignItems="center">
                    <Button
                      data-testid={`edit-group-button-${group.shortName}`}
                      size="xs"
                      variant="outline"
                      onClick={() => {
                        setEditGroup(group.shortName)
                        editGroupDisclosure.onOpen()
                      }}
                    >
                      Edit
                    </Button>
                  </GridItem>
                </Grid>
              </Box>
            ))}
        </Stack>
        <Button data-testid="create-group-button" size="sm" onClick={createGroupDisclosure.onOpen}>
          + Create group
        </Button>
      </FormControl>
    </Stack>
  )
}
