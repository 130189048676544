import { useState } from 'react'
import QRCode from '../../shared/QRCode'
import {
  Box,
  Stack,
  Container,
  FormControl,
  FormLabel,
  FormHelperText,
  Button,
  Text,
  Select,
  useDisclosure,
  RadioGroup,
  Radio,
} from '@chakra-ui/react'
import { db, StoreData, SupportedLangs } from '@flowby/shared-firebase'
import ConfirmAlert from '../../shared/ConfirmAlert'
import AddDataField from './AddDataField'
import { useToast } from '../../shared/Toast'
import FormikForm from '../../shared/FormikForm'
import { translations } from '@flowby/shared-general'
import { useDocData } from '../../../libs/firebaseHooks'
import { posthog } from 'posthog-js'

export default function EditQueueForm({
  store,
  storeData,
  queue,
  onFinish,
}: {
  store: string,
  storeData: StoreData,
  queue: string,
  onFinish: () => void
}) {
  const [deleteQueueLoading, setDeleteQueueLoading] = useState(false)
  const [queueData, queueLoading] = useDocData(db.getQueueRef(store, queue), [store, queue])
  const deleteQueueDisclosure = useDisclosure()
  const toast = useToast()

  const onEditQueueDisplayName = async (data: { [key: string]: any }) => {
    try {
      if (!data.queueDisplayName) {
        throw new Error('Missing queue display name')
      }
      await db.updateQueue(store, queue, {
        displayName: data.queueDisplayName,
      })
      toast('success', 'Queue name updated.')
    } catch (e) {
      toast('error', null, 'EditQueueForm/edit-queue-display-name-error', e)
    }
  }

  const onEditQueueTranslations = async (data: { [key: string]: any }) => {
    try {
      const lang = Object.keys(data)[0]?.split('-')[0]
      const translate = {
        [`translations.${lang}.yourTurn`]: data[`${lang}-yourTurn`]
      }
      await db.updateQueue(store, queue, translate)
      toast('success', 'Queue translation updated.')
    } catch (e) {
      toast('error', null, 'EditQueueForm/edit-translations-error', e)
    }
  }

  const onEditNotificationConfiguration = async (notifyWhenTurnsUntilMyTurn: string) => {
    try {
      const updateConfig = {
        'config.notifyWhenTurnsUntilMyTurn': Number(notifyWhenTurnsUntilMyTurn)
      }
      await db.updateQueue(store, queue, updateConfig)
      toast('success', 'SMS configuration updated.')
    } catch (e) {
      toast('error', null, 'EditQueueForm/edit-notification-configuration-error', e)
    }
  }

  const onEditQueueOrderConfiguration = async (ordered: string) => {
    const isUnordered = ordered === 'unordered'
    try {
      const updateConfig = {
        'config.queueIsUnordered': isUnordered
      }
      await db.updateQueue(store, queue, updateConfig)
      toast('success', 'Queue order updated.')
    } catch (e) {
      toast('error', null, 'EditQueueForm/edit-queue-order-configuration-error', e)
    }
  }

  const onEditKeepMyTurnConfiguration = async (enabled: string) => {
    const isEnabled = enabled === 'enabled'
    try {
      const updateConfig = {
        'config.keepMyTurnOnThanksPage': isEnabled
      }
      await db.updateQueue(store, queue, updateConfig)
      toast('success', 'Use MyTurn on Thanks Page config updated.')
    } catch (e) {
      toast('error', null, 'EditQueueForm/edit-keep-my-turn-configuration-error', e)
    }
  }

  const onEditQueueThroughputConfiguration = async (data: { [key: string]: any }) => {
    try {
      let updateConfig: {
        'config.estimatedThroughput': number | null
      } = {
        'config.estimatedThroughput': Number(data.queueEstimatedThroughput)
      }
      if (!data.queueEstimatedThroughput) {
        updateConfig = {
          'config.estimatedThroughput': null
        }
      }
      await db.updateQueue(store, queue, updateConfig)
      toast('success', 'Average service time updated.')
    } catch (e) {
      toast('error', null, 'EditQueueForm/edit-estimated-throughput-error', e)
    }
  }

  const onDeleteQueue = async () => {
    if (queue) {
      try {
        setDeleteQueueLoading(true)
        await db.deleteQueue(store, queue)
        setDeleteQueueLoading(false)
        deleteQueueDisclosure.onClose()
        toast('success', 'Queue deleted.')
        onFinish()
      } catch (e) {
        toast('error', null, 'EditQueueForm/delete-queue-error', e)
        setDeleteQueueLoading(false)
      }
    }
  }

  const messageTranslationFields = (storeData.supportedLangs).map((lang) => {
    return {
      type: 'textInput',
      id: `${lang}-yourTurn`,
      helperText: translations[lang as SupportedLangs].name,
      formHelperTextStyle: { margin: 0 },
      initialValue: queueData?.translations?.[lang]?.yourTurn || '',
      validation: (value: string) => {
        if (!value || value.length === 0) {
          return 'You need to add a call to action text for all supported languages.'
        }
        return undefined
      }
    }
  })

  return (
    <Stack spacing={4}>
      <ConfirmAlert
        disclosure={deleteQueueDisclosure}
        headerText="Delete Queue"
        questionText="Do you want to delete the queue?"
        noText="No"
        yesText="Yes"
        yesLoading={deleteQueueLoading}
        yesAction={onDeleteQueue}
      />
      {!queueLoading && queue && queueData && (
        <Container>
          <Stack spacing={4}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <QRCode store={store} queueOrGroup={queue} isGroup={false} />
            </Box>
            <FormControl>
              <FormLabel>Queue URL</FormLabel>
              <FormHelperText>
                The web address of the queue.
              </FormHelperText>
              <Text>{`${process.env.REACT_APP_ENV === 'dev' ? 'https://dev.go.flowby.io' : 'https://go.flowby.io'}/${store}/q/${queue}`}</Text>
            </FormControl>
            <FormControl>
              <FormLabel>Queue Kiosk URL</FormLabel>
              <FormHelperText>
                The web address of the queue kiosk. The kiosk allows your customers to queue via an in-store tablet
                device (i.e. iPad).
              </FormHelperText>
              <Text>{`${process.env.REACT_APP_ENV === 'dev' ? 'https://dev.go.flowby.io' : 'https://go.flowby.io'}/${store}/q/${queue}/kiosk`}</Text>
            </FormControl>
            <FormikForm
              fields={[
                {
                  type: 'textInput',
                  id: 'queueDisplayName',
                  label: 'Queue Name',
                  initialValue: queueData.displayName,
                  helperText: 'Display name of the queue.',
                  validation: (value: string) => {
                    if (!value || value.length === 0) {
                      return 'Queue name is required.'
                    }
                    if (value.length > 24) {
                      return 'Queue name is too long. Max is 24 characters.'
                    }
                    return undefined
                  }
                }
              ]}
              inlineSubmitButton={true}
              submitButtonText="Save"
              onSubmit={async (values) => {
                await onEditQueueDisplayName(values)
              }}
            />
            <FormControl>
              <FormLabel>Call To Action Text</FormLabel>
              <FormHelperText m={0}>
                This message will be displayed to the customer when it is their turn.
              </FormHelperText>
            </FormControl>
            {messageTranslationFields.map((field) => {
              return <FormikForm
                key={field.id}
                fields={[
                  field
                ]}
                submitButtonText="Save"
                inlineSubmitButton={true}
                onSubmit={async (values) => {
                  await onEditQueueTranslations(values)
                }}
              />
            })}
            <FormControl id="queueOptions" overflow="hidden">
              <FormLabel>SMS Configuration</FormLabel>
              <FormHelperText>
                You can choose at what position in the queue your customers should get notified. This usually depends on
                the serving time and the distance to the service station. Notification is sent when:
              </FormHelperText>
              <Select
                value={queueData?.config?.notifyWhenTurnsUntilMyTurn !== undefined ?
                  queueData?.config?.notifyWhenTurnsUntilMyTurn : 1
                }
                onChange={e => onEditNotificationConfiguration(e.target.value)}
              >
                <option key={0} value={-1}>
                  Never (Disable SMS notifications)
                </option>
                <option key={1} value={0}>
                  It is the customer's turn
                </option>
                <option key={2} value={1}>
                  The customer is first in the queue
                </option>
                <option key={3} value={2}>
                  The customer has one person ahead of them in the queue
                </option>
              </Select>
            </FormControl>
            <AddDataField store={store} storeData={storeData} queueData={queueData} queue={queue} />
            {posthog.isFeatureEnabled('admin-feature-selection') && (
              <Box p={4} shadow="md" borderWidth="1px" bg="white" borderRadius="0.375rem">
                <Text pb={2} color="gray.700">Flowby admin options (hidden for customers)</Text>
                <FormControl id="queueOrder">
                  <FormLabel>Queue order</FormLabel>
                  <FormHelperText>
                    This option enables the special queue case where the staff picks each
                    customer in the queue from a list with no regards to the order they entered the queue.
                    If queue is unordered the customer will not see their place in the queue and the
                    staff's queue view will show a list of queurs by default.
                  </FormHelperText>
                  <RadioGroup onChange={onEditQueueOrderConfiguration} defaultValue="ordered" value={queueData?.config?.queueIsUnordered ? 'unordered' : 'ordered'}>
                    <Stack direction='row'>
                      <Radio value='ordered'>Queue is ordered</Radio>
                      <Radio value='unordered'>Queue is unordered</Radio>
                    </Stack>
                  </RadioGroup>
                </FormControl>
                <FormControl id="keepMyTurnOnThanksPage">
                  <FormLabel>Use MyTurn Page as Thanks Page</FormLabel>
                  <FormHelperText>
                    This option enables the special queue case where we want to use the MyTurn page as the Thanks page.
                  </FormHelperText>
                  <RadioGroup onChange={onEditKeepMyTurnConfiguration} defaultValue="disabled" value={queueData?.config?.keepMyTurnOnThanksPage ? 'enabled' : 'disabled'}>
                    <Stack direction='row'>
                      <Radio value='enabled'>Enable</Radio>
                      <Radio value='disabled'>Disable</Radio>
                    </Stack>
                  </RadioGroup>
                </FormControl>
              </Box>
            )}
            <FormikForm
              fields={[
                {
                  type: 'textInput',
                  id: 'queueEstimatedThroughput',
                  label: 'Average service time',
                  initialValue: queueData.config?.estimatedThroughput || '',
                  helperText: `Input average service time in minutes so that your customers 
                    get an estimation of how much time they will be standing in the queue until
                    it is their turn. The customer will see the estimated wait time rounded up to the nearest 5 minutes.`,
                  validation: (value: string) => {
                    if (!value || value.length === 0) {
                      return undefined
                    }
                    if (
                      isNaN(value as any) ||
                      Number(value) === 0 ||
                      Number(value) < 0 ||
                      !Number.isInteger(Number(value))
                    ) {
                      return 'The value needs to be a positive integer.'
                    }
                    return undefined
                  }
                }
              ]}
              inlineSubmitButton={true}
              submitButtonText="Save"
              onSubmit={async (values) => {
                await onEditQueueThroughputConfiguration(values)
              }}
            />
            <FormControl id="deleteQueue">
              <FormLabel>Delete Queue</FormLabel>
              <Button
                data-testid="delete-queue-button"
                colorScheme="red"
                size="sm"
                onClick={deleteQueueDisclosure.onOpen}
              >
                Delete Queue
              </Button>
            </FormControl>
          </Stack>
        </Container>
      )}
    </Stack>
  )
}
