import {
  Stack,
  Box,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useDisclosure,
  IconButton,
} from '@chakra-ui/react'
import { db } from '@flowby/shared-firebase'
import { useEffect, useState } from 'react'
import { IoTrashBinOutline } from 'react-icons/io5'
import ConfirmAlert from '../shared/ConfirmAlert'
import Loader from '../shared/Loader'
import ModalContainer from '../shared/ModalContainer'
import CreateUserForm from './CreateUserForm'
import SetUserAccessForm from './SetUserAccessForm'
import { useToast } from '../shared/Toast'

export default function Users() {
  const createUserDisclosure = useDisclosure()
  const deleteUserDisclosure = useDisclosure()
  const setUserAccessDisclosure = useDisclosure()
  const toast = useToast()
  const [users, setUsers] = useState<any[] | null>(null)
  const [loading, setLoading] = useState(false)
  const [selectedUserId, setSelectedUserId] = useState<string | undefined>(undefined)
  const [selectedUserEmail, setSelectedUserEmail] = useState<string | undefined>(undefined)
  const [deleteUserLoading, setDeleteUserLoading] = useState<boolean>(false)

  useEffect(() => {
    getUsers().then(users => {
      if (users) {
        setUsers(users)
      }
    })
  }, [])

  const deleteUser = async () => {
    setDeleteUserLoading(true)
    try {
      if (!selectedUserId) {
        throw new Error('No user selected.')
      }
      await db.adminDeleteUser({ userId: selectedUserId })
      toast('success', `Deleted user with email ${selectedUserEmail}. Reload the page to see the change.`)
      deleteUserDisclosure.onClose()
    } catch (e) {
      toast('error', `Failed to delete user with email ${selectedUserEmail}.`, 'FlowbyAdmin/delete-user-error', e)
    }
    setDeleteUserLoading(false)
  }

  const getUsers = async () => {
    setLoading(true)
    try {
      setLoading(false)
      const users = await db.adminGetUsers()
      return users.data.users
    } catch (e) {
      setLoading(false)
    }
  }

  if (loading) {
    return <Loader />
  }

  return (
    <>
      <ModalContainer
        isOpen={createUserDisclosure.isOpen}
        onClose={createUserDisclosure.onClose}
        header="Create new user"
        content={<CreateUserForm onFinish={createUserDisclosure.onClose} />}
      />
      <ModalContainer
        isOpen={setUserAccessDisclosure.isOpen}
        onClose={setUserAccessDisclosure.onClose}
        header="Give user access"
        content={<SetUserAccessForm users={users ? users : []} onFinish={setUserAccessDisclosure.onClose} />}
      />
      <ConfirmAlert
        disclosure={deleteUserDisclosure}
        headerText="Delete user"
        questionText={`Are you sure you want to delete the user with email ${selectedUserEmail}?`}
        noText="No"
        yesText="Yes"
        yesAction={deleteUser}
        yesLoading={deleteUserLoading}
        validateString={selectedUserEmail}
        validatePlaceholder="Type the user email to confirm deletion."
      />

      <Stack>
        <Box>
          <Button onClick={createUserDisclosure.onOpen} mr={2} size="md">
            Create new user
          </Button>
          <Button onClick={setUserAccessDisclosure.onOpen} variant="outline" size="md">
            Give user access to store
          </Button>
        </Box>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Email</Th>
              <Th>Store</Th>
              <Th>Verified</Th>
              <Th>Admin</Th>
              <Th>Recent Activity</Th>
              <Th>Created At</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {users &&
              users
                .sort((userA, userB) => {
                  const userAProp = userA.customClaims?.store ? userA.customClaims?.store : 'z'
                  const userBProp = userB.customClaims?.store ? userB.customClaims?.store : 'z'
                  return userAProp < userBProp ? -1 : userAProp > userBProp ? 1 : 0
                })
                .map(user => {
                  return (
                    <Tr key={user.email}>
                      <Td>{user.email}</Td>
                      <Td>{user.customClaims?.store ? user.customClaims.store : 'N/A'}</Td>
                      <Td>{user.emailVerified ? 'true' : 'false'}</Td>
                      <Td>{user.customClaims?.admin ? 'true' : 'false'}</Td>
                      <Td>{user.metadata.lastSignInTime}</Td>
                      <Td>{user.metadata.creationTime}</Td>
                      <Td>
                        <Stack direction="row">
                          <IconButton
                            colorScheme="red"
                            size="sm"
                            aria-label="Visit"
                            icon={<IoTrashBinOutline />}
                            onClick={() => {
                              setSelectedUserId(user.uid)
                              setSelectedUserEmail(user.email)
                              deleteUserDisclosure.onOpen()
                            }}
                          />
                        </Stack>
                      </Td>
                    </Tr>
                  )
                })}
          </Tbody>
        </Table>
      </Stack>
    </>
  )
}
