import en from './translations.en.json'
import sv from './translations.sv.json'
import no from './translations.no.json'
import fi from './translations.fi.json'

export const translations = {
  en,
  sv,
  no,
  fi
}
