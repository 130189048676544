import { useNavigate } from 'react-router-dom'
import {
  Stack,
  Heading,
  Flex,
  Container,
  useDisclosure,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from '@chakra-ui/react'
import { db, firebase } from '@flowby/shared-firebase'
import Header from '../components/shared/Header'
import { IoExitOutline } from 'react-icons/io5'
import { useEffect, useState } from 'react'
import ModalContainer from '../components/shared/ModalContainer'
import CreateStoreForm from '../components/FlowbyAdmin/CreateStoreForm'
import CreateUserForm from '../components/FlowbyAdmin/CreateUserForm'
import SetUserAccessForm from '../components/FlowbyAdmin/SetUserAccessForm'
import Stores from '../components/FlowbyAdmin/Stores'
import Users from '../components/FlowbyAdmin/Users'
import { useToast } from '../components/shared/Toast'

export default function FlowbyAdminPage({ admin }: { admin: boolean }) {
  const navigate = useNavigate()
  useEffect(() => {
    if (!admin) {
      navigate('/login')
    }
  }, [admin])
  const createStoreDisclosure = useDisclosure()
  const createUserDisclosure = useDisclosure()
  const setUserAccessDisclosure = useDisclosure()
  const [users, setUsers] = useState<any[] | null>(null)
  const toast = useToast()

  useEffect(() => {
    getUsers().then(users => {
      if (users) {
        setUsers(users)
      }
    })
  }, [])

  const getUsers = async () => {
    try {
      const response = await db.adminGetUsers()
      return response.data.users
    } catch (e) {
      toast('error', null, 'FlowbyAdmin/get-users-error', e)
    }
  }

  return (
    <Flex direction="column" minH={'90vh'}>
      <ModalContainer
        isOpen={createStoreDisclosure.isOpen}
        onClose={createStoreDisclosure.onClose}
        header="Create new store"
        content={<CreateStoreForm onFinish={createStoreDisclosure.onClose} />}
      />
      <ModalContainer
        isOpen={createUserDisclosure.isOpen}
        onClose={createUserDisclosure.onClose}
        header="Create new user"
        content={<CreateUserForm onFinish={createUserDisclosure.onClose} />}
      />
      <ModalContainer
        isOpen={setUserAccessDisclosure.isOpen}
        onClose={setUserAccessDisclosure.onClose}
        header="Create new user"
        content={<SetUserAccessForm users={users ? users : []} onFinish={setUserAccessDisclosure.onClose} />}
      />

      <Header
        leftComponent={{
          text: 'Logout',
          icon: <IoExitOutline style={{ transform: 'scaleX(-1)' }} size={24} />,
          onClick: () => {
            firebase.auth.signOut()
            navigate('/')
          },
        }}
        rightComponent={null}
      />
      <Container maxW="container.xl">
        <Stack spacing={8} mx={'auto'} py={{ base: 4, md: 12 }}>
          <Heading textAlign="center">Flowby Admin</Heading>
          <Tabs isLazy>
            <TabList>
              <Tab>🏠 Stores</Tab>
              <Tab>🧑 Users</Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <Stores />
              </TabPanel>
              <TabPanel>
                <Users />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Stack>
      </Container>
    </Flex>
  )
}
