import { useState } from 'react'
import { auth } from '@flowby/shared-firebase'
import {
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button
} from '@chakra-ui/react'
import { useToast } from '../shared/Toast'

export default function CreateUser() {
  const toast = useToast()
  const [email, setEmail] = useState('')
  const [passwordOne, setPasswordOne] = useState('')
  const [passwordTwo, setPasswordTwo] = useState('')
  const [loading, setLoading] = useState(false)

  const isInvalid =
    passwordOne === '' ||
    passwordTwo === '' ||
    email === ''

  const createUser = async () => {
    setLoading(true)
    try {
      const { user } = await auth.createUser(email, passwordOne)
      await auth.sendVerificationEmail(user, { url: window.location.href })
      setLoading(false)
    } catch (e: any) {
      setLoading(false)
      if (e.code) {
        if (e.code === 'auth/email-already-in-use') {
          toast(
            'error',
            'An account with this email already exists.',
            'Signup/email-already-in-use',
            e
          )
        } else if (e.code === 'auth/weak-password') {
          toast(
            'error',
            'The password is too short. It needs to be at least 6 characters.',
            'Signup/weak-password',
            e
          )
        } else {
          toast("error", "User could not be created.", "Signup/user-not-created", e)
        }
      } else {
        toast("error", "User could not be created.", "Signup/user-not-created", e)
      }
      return
    }
  }

  return (
    <Stack spacing={4}>
      <FormControl id="email">
        <FormLabel>Email</FormLabel>
        <Input name="email" type="email" value={email} onChange={e => setEmail(e.target.value)} />
      </FormControl>
      <FormControl id="passwordOne">
        <FormLabel>Password</FormLabel>
        <Input name="passwordOne" type="password" value={passwordOne} onChange={e => setPasswordOne(e.target.value)} />
      </FormControl>
      <FormControl id="passwordTwo">
        <FormLabel>Confirm Password</FormLabel>
        <Input name="passwordTwo" type="password" value={passwordTwo} onChange={e => setPasswordTwo(e.target.value)} />
      </FormControl>
      <Stack pt={2}>
        <Button disabled={isInvalid} isLoading={loading} onClick={createUser}>
          Next
        </Button>
      </Stack>
    </Stack>
  )
}
