import { useNavigate, Link as RouterLink } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { auth, User } from '@flowby/shared-firebase'
import Header from '../components/shared/Header'
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Link,
  Button,
  Heading,
  Text,
  Container,
  Center,
} from '@chakra-ui/react'
import { IoAddCircleOutline } from 'react-icons/io5'
import { useToast } from '../components/shared/Toast'
import { isNative } from '../libs/native'

export default function LoginPage({ user }: { user: User }) {
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const toast = useToast()

  useEffect(() => {
    if (user) {
      navigate('/')
    }
  }, [user])

  const signIn = async (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    setLoading(true)
    try {
      await auth.signIn(email, password)
      navigate('/')
    } catch (error) {
      setLoading(false)
      toast('error', 'Login unsuccessful. Please check your credentials.', 'Login/failed-login', error)
    }
  }

  const isInvalid = password === '' || email === ''

  return (
    <Flex minH={'90vh'} flexDirection="column">
      <Header
        leftComponent={null}
        rightComponent={!isNative() ? {
          text: 'Signup',
          icon: <IoAddCircleOutline size={24} />,
          onClick: () => navigate('/signup'),
        } : null}
      />
      <Container data-testid="login-container">
        <Stack spacing={8} mx={'auto'} maxW={'lg'} py={{ base: 8, md: 12 }} px={{ base: 0, md: 6 }}>
          <Stack align={'center'}>
            <Heading fontSize={'4xl'}>Welcome &#128075;</Heading>
            <Text fontSize="lg">Login below to view your store.</Text>
          </Stack>
          <Box
            rounded={'lg'}
            p={8}
            borderWidth="1px"
            bg="white"
            shadow="md"
            borderColor="gray.200"
            borderRadius="0.375rem"
          >
            <Stack spacing={4}>
              <form>
                <FormControl id="email">
                  <FormLabel>Email address</FormLabel>
                  <Input name="email" type="email" value={email} onChange={e => setEmail(e.target.value)} />
                </FormControl>
                <FormControl id="password">
                  <FormLabel>Password</FormLabel>
                  <Input name="password" type="password" value={password} onChange={e => setPassword(e.target.value)} />
                </FormControl>
                <Stack spacing={5}>
                  <Box>
                    <Link as={RouterLink} to="/forgotpassword">
                      Forgot password?
                    </Link>
                  </Box>
                  <Button type="submit" isLoading={loading} disabled={isInvalid} onClick={signIn}>
                    Sign in
                  </Button>
                </Stack>
              </form>
            </Stack>
          </Box>
          {!isNative() && (
            <Center>
              <Link as={RouterLink} to="/signup">
                Don't have an account yet?
              </Link>
            </Center>
          )}
        </Stack>
      </Container>
    </Flex>
  )
}
