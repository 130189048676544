import { Stack, Text } from '@chakra-ui/react'
import slugify from 'slugify'
import { QueueData, QueueTranslations, StoreData, SupportedLangs } from '@flowby/shared-firebase'
import { useToast } from '../../shared/Toast'
import FormikForm from '../../shared/FormikForm'
import { translations } from '@flowby/shared-general'
import { db } from '@flowby/shared-firebase'

export default function CreateQueueForm({ store, storeData, queuesData, onFinish }: {
  store: string,
  storeData: StoreData,
  queuesData: QueueData[]
  onFinish: (queue: string) => void
}) {
  const toast = useToast()
  const createQueue = async (data: { [key: string]: string }) => {
    try {
      if (queuesData.find((queue) => queue.shortName === data.queueShortName)) {
        toast('error', 'A queue with the id already exists.', 'CreateQueueForm/create-queue-error')
        return
      }
      if (!data.queueShortName || !data.queueDisplayName) {
        toast('error', 'Missing queue data.', 'CreateQueueForm/create-queue-error')
        return
      }
      data.queueShortName = slugify(data.queueShortName, { lower: true }) // trims starting or ending dashes
      const translations = (storeData.supportedLangs).reduce((acc, lang) => {
        const yourTurnTranslation = data[`${lang}-yourTurn`]
        if (!yourTurnTranslation) {
          toast('error', 'Missing translation for required language.', 'CreateQueueForm/create-queue-error')
          throw new Error('Missing translation for required language.')
        }
        acc[lang] = {
          yourTurn: yourTurnTranslation,
        }
        return acc
      }, {} as QueueTranslations)

      await db.createQueue(
        store,
        data.queueDisplayName,
        data.queueShortName,
        translations
      )
      toast('success', 'Queue created.')
      onFinish(data.queueShortName)
    } catch (e) {
      toast('error', null, 'CreateQueueForm/create-queue-error', e)
    }
  }

  const messageTranslationFieldsInitialValues: { [key: string]: string } = {
    en: 'Go to cashier!',
    sv: 'Gå till kassan!',
  }
  const messageTranslationFields = (storeData.supportedLangs).map((lang) => {
    return {
      type: 'textInput',
      id: `${lang}-yourTurn`,
      helperText: translations[lang as SupportedLangs].name,
      formHelperTextStyle: { margin: 0 },
      initialValue: messageTranslationFieldsInitialValues[lang] || '',
      validation: (value: string) => {
        if (!value || value.length === 0) {
          return 'You need to add a call to action text for all supported languages.'
        }
        return undefined
      }
    }
  })

  return (
    <Stack spacing={4}>
      <Text>Fill in the fields below to create your new queue. Keep in mind that you will be billed per queue.</Text>
      <FormikForm
        fields={[{
          type: 'textInput',
          id: 'queueDisplayName',
          label: 'Queue Name',
          helperText: 'This will be the display name of your queue.',
          validation: (value: string) => {
            if (!value || value.length === 0) {
              return 'Queue name is required.'
            }
            if (value.length > 24) {
              return 'Queue name is too long. Max is 24 characters.'
            }
            return undefined
          },
          onChange: (e, props) => {
            props.setFieldValue('queueShortName', slugify(e.target.value, { lower: true }))
            props.handleChange(e)
          }
        },
        {
          type: 'label',
          id: 'messageTranslations',
          label: 'Call To Action Text',
          helperText: 'This message will be displayed to the customer when it is their turn.'
        },
        ...messageTranslationFields
        ]}
        submitButtonText="Create Queue"
        onSubmit={async (values) => {
          await createQueue(values)
        }}
      />
    </Stack>
  )
}
