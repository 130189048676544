import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { translations } from '@flowby/shared-general'

export const resources = {
  sv: translations.sv,
  en: translations.en,
} as const

i18n.use(initReactI18next).init({
  compatibilityJSON: 'v3', // old browser compatability: https://stackoverflow.com/questions/70493788/i18nextpluralresolver-your-environment-seems-not-to-be-intl-api-compatible-u
  fallbackLng: 'en', // TODO: should set default language if not set by user to store default
  interpolation: {
    escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
  },
  resources,
})
