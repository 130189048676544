import { useState } from 'react'
import ReactFlagsSelect from 'react-flags-select'
import slugify from 'slugify'
import { SupportedLangs } from '@flowby/shared-firebase'
import { translations } from '@flowby/shared-general'
import {
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Stack,
  Button,
  Link,
  Select,
  Checkbox,
} from '@chakra-ui/react'
import { useToast } from '../shared/Toast'
import { db } from '@flowby/shared-firebase'

export default function CreateStore({ setStore }: {
  setStore: (store: string) => void
}) {
  const toast = useToast()
  const [name, setName] = useState('')
  const [shortName, setShortName] = useState('')
  const [language, setLanguage] = useState<SupportedLangs | ''>('')
  const [country, setCountry] = useState('')
  const [tos, setTos] = useState(false)
  const [loading, setLoading] = useState(false)

  const isInvalid =
    name === '' ||
    shortName === '' ||
    language === '' ||
    country === '' ||
    !tos

  const onSubmitSignup = async () => {
    setLoading(true)
    if (!language || !country || !name || !shortName) {
      toast(
        'error',
        'Please fill out all fields and try again.',
        'Signup/missing-fields'
      )
      return
    }
    try {
      const response = await db.createStore({ name, country, lang: language, supportedLangs: [language, 'en'], shortName })
      if (response.data && response.data.success === false) {
        toast('error', response.data.error || 'Something went wrong.', 'Signup/error-creating-store')
        setLoading(false)
        return
      }
      setStore(shortName)
      setLoading(false)
    } catch (e: any) {
      setLoading(false)
      toast('error', null, 'Signup/error-creating-store', e)
    }
  }

  return (
    <Stack spacing={4}>
      <FormControl id="name">
        <FormLabel>Store Name</FormLabel>
        <Input
          name="name"
          type="text"
          value={name}
          onChange={e => {
            setShortName(slugify(e.target.value, { lower: true }))
            setName(e.target.value)
          }}
        />
      </FormControl>
      <FormControl id="shortName">
        <FormLabel>Store ID</FormLabel>
        <FormHelperText>Unique name and id that will be shown to your customers in the web address url.</FormHelperText>
        <Input
          name="shortName"
          type="text"
          value={shortName}
          onChange={e => setShortName(slugify(e.target.value, { lower: true, trim: false }))}
        />
      </FormControl>
      <FormControl id="language">
        <FormLabel>Primary Language</FormLabel>
        <FormHelperText>
          The primary language will be shown to your customers by default.
          Additional languages can be added in the store settings.
        </FormHelperText>
        <Select color="#4d4d4d" placeholder="Select language" onChange={e => setLanguage(e.target.value as SupportedLangs)}>
          {Object.keys(translations).map((key: string) => {
            const k = key as SupportedLangs
            return (
              <option key={k} value={k}>
                {(translations)[k].name}
              </option>
            )
          })}
        </Select>
      </FormControl>
      <FormControl id="country">
        <FormLabel>Country</FormLabel>
        <ReactFlagsSelect
          searchable={true}
          selected={country}
          searchPlaceholder="Input search query here..."
          onSelect={setCountry}
        />
      </FormControl>
      <FormControl id="tos">
        <Checkbox
          name="tos"
          isChecked={tos}
          onChange={e => {
            setTos(e.target.checked)
          }}
        >
          I agree to the{' '}
          <Link href="https://flowby.io/terms" isExternal>
            Terms of Service
          </Link>
          {' '}and{' '}
          <Link href="https://flowby.io/privacy" isExternal>
            Privacy Policy
          </Link>
          .
        </Checkbox>
      </FormControl>
      <Stack spacing={5}>
        <Button disabled={isInvalid} isLoading={loading} onClick={onSubmitSignup}>
          Next
        </Button>
      </Stack>
    </Stack>
  )
}
