import { useState } from 'react'
import { User } from '@flowby/shared-firebase'
import {
  Box,
  Stack,
  Button,
  Text,
} from '@chakra-ui/react'
import { useToast } from '../shared/Toast'

export default function VerifyEmail({ user, setEmailVerified }: {
  user: User,
  setEmailVerified: (verified: boolean) => void
}) {
  const toast = useToast()
  const [loading, setLoading] = useState(false)

  const onVerifyEmail = async () => {
    setLoading(true)
    try {
      await user.reload()
      if (user.emailVerified) {
        setEmailVerified(true)
        setLoading(false)
      } else if (user) {
        setLoading(false)
        toast(
          'error',
          'You need to verify your email by clicking the link in the email we sent to you to continue.',
          'Signup/email-not-verified'
        )
      }
    } catch (e) {
      toast(
        'error',
        null,
        'Signup/unknown-verify-email-error',
        e
      )
    }
  }

  return (
    <Stack textAlign="center" spacing={4}>
      <Text>
        We have sent a confirmation email to{' '}
        <Box as="span" fontWeight="bold">
          {user.email}
        </Box>
        {' '}
        with a link. Please click the link and verify your email address to proceed.
      </Text>
      <Button isLoading={loading} onClick={onVerifyEmail}>
        Next
      </Button>
    </Stack>
  )
}
