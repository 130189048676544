import { Stack } from '@chakra-ui/react'
import slugify from 'slugify'
import { db, SupportedLangs, supportedLangs } from '@flowby/shared-firebase'
import FormikForm from '../shared/FormikForm'
import { useToast } from '../shared/Toast'

export default function CreateStoreForm({ onFinish }: { onFinish: () => void }) {
  const toast = useToast()

  const createStore = async (data: { [key: string]: any }) => {
    try {
      if (!data.name || !data.slug || !data.country || !data.lang || !data.supportedLangs) {
        throw new Error('Missing data when creating store.')
      }
      const transformedSupportedLangs = data.supportedLangs.replace(' ', '').split(',')
      if (!transformedSupportedLangs.includes(data.lang)) {
        throw new Error('Primary language must be included in supported languages.')
      }
      await db.adminCreateStore({
        name: data.name,
        country: data.country,
        lang: data.lang,
        supportedLangs: transformedSupportedLangs,
        slug: data.slug
      })
      toast('success', 'Store created.')
      onFinish()
    } catch (e) {
      toast('error', null, 'AdminCreateStoreForm/create-store-error', e)
    }
  }

  return (
    <Stack spacing={4}>
      <FormikForm
        fields={[
          {
            type: 'textInput',
            id: 'name',
            label: 'Store Name',
            helperText: 'This will be the display name of the store.',
            validation: (value: string) => {
              if (!value || value.length === 0) {
                return 'Store name is required.'
              }
              if (value.length > 30) {
                return 'Store name is too long. Max is 30 characters.'
              }
              return undefined
            },
            onChange: (e, props) => {
              props.setFieldValue('slug', slugify(e.target.value, { lower: true }))
              props.handleChange(e)
            }
          },
          {
            type: 'textInput',
            id: 'slug',
            label: 'Store ID',
            helperText: 'Unique name and id that will be shown to your customers in the web address url.',
            validation: (value: string) => {
              if (!value || value.length === 0) {
                return 'Store ID is required.'
              }
              if (value.length > 30) {
                return 'Store ID is too long. Max is 30 characters.'
              }
              return undefined
            },
            onChange: (e, props) => {
              props.setFieldValue('slug', slugify(e.target.value, { lower: true, trim: false }))
            }
          },
          {
            type: 'textInput',
            id: 'country',
            label: 'Store Country',
            helperText: 'Two letter ISO country code. For example SE for Sweden.',
            validation: (value: string) => {
              if (!value || value.length === 0) {
                return 'Store country is required.'
              }
              const supportedCountries = ['SE', 'GB', 'NO', 'FI']
              if (!supportedCountries.includes(value)) {
                return `Store country is not supported. Supported countries are: ${supportedCountries.join(', ')}`
              }
              return undefined
            },
          },
          {
            type: 'textInput',
            id: 'lang',
            label: 'Primary Language',
            helperText: 'Two letter ISO language code. For example sv for Swedish.',
            validation: (value: string) => {
              if (!value || value.length === 0) {
                return 'Primary language is required.'
              }
              const supportedLanguages = supportedLangs
              if (!supportedLanguages.includes(value as SupportedLangs)) {
                return `Primary language is not supported. Supported countries are: ${supportedLanguages.join(', ')}`
              }
              return undefined
            },
          },
          {
            type: 'textInput',
            id: 'supportedLangs',
            label: 'Supported Languages',
            helperText: 'Two letter ISO language codes separated by commas. For example en, sv, fi for English, Swedish and Finnish.',
            validation: (value: string) => {
              if (!value || value.length === 0) {
                return 'Supported languages are required.'
              }
              const supportedLanguages = supportedLangs
              const suppliedLanguages = value.replace(' ', '').split(',')
              suppliedLanguages.map((lang) => {
                if (!supportedLanguages.includes(lang as SupportedLangs)) {
                  return `One of the supported languages is not supported. Supported countries are: ${supportedLanguages.join(', ')}`
                }
              })
              return undefined
            }
          }
        ]}
        onSubmit={async (values) => {
          await createStore(values)
        }}
        submitButtonText="Create Store"
      />
    </Stack>
  )
}
