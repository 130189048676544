import create from 'zustand'
import { persist } from 'zustand/middleware'

type StoreState = {
  openQueuesInGroupAccordion: number
  setOpenQueuesInGroupAccordion: (val: number) => void
}

const useStore = create<StoreState>(
  persist(
    set => ({
      openQueuesInGroupAccordion: 0, // -1 means closed and 0 means open
      setOpenQueuesInGroupAccordion: val => set({ openQueuesInGroupAccordion: val }),
    }),
    {
      name: 'flowby-admin-storage',
    },
  ),
)

export default useStore
