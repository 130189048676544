import moment from "moment"
import 'moment-timezone'
import * as timezones from './timezones.json'
const tz = timezones as any

export const getDate = () => {
  const date = new Date()
  return `${date.getUTCFullYear()}-${date.getUTCMonth() + 1}-${date.getUTCDate()}`
}

// Currently this naively takes the first time zone in the country's list of timezones
export const getTimeZoneDiffFromUTC = (countryCode: string) => {
  try {
    const timezone = tz[countryCode][0]
    const offset = moment.tz.zone(timezone)?.utcOffset(Date.now())
    if (offset === undefined) throw new Error('offset is undefined')
    return offset / 60
  } catch (e) {
    return null
  }
}

// copied from functions, tests are there, do not change here
export const shouldWarnAutoEmptyQueues = (
  autoEmptyQueues: number,
  now: number,
  timeZoneDiffFromUTC: number
) => {
  const emptyTime = autoEmptyQueues
  let emptyTimeInUTC = emptyTime + timeZoneDiffFromUTC
  // to account for times around midnight
  if (emptyTimeInUTC > 24) {
    emptyTimeInUTC -= 24
  }
  if (emptyTimeInUTC < 0) {
    emptyTimeInUTC += 24
  }
  if (emptyTimeInUTC - now === 1 || emptyTimeInUTC - now === 2) {
    return true
  }
  return false
}