import { useParams } from 'react-router-dom'
import GroupKioskView from '../components/Kiosk/GroupKioskView'
import Loader from '../components/shared/Loader'
import Error from '../components/shared/Error'
import { useCollData, useDocData } from '../libs/firebaseHooks'
import { db } from '@flowby/shared-firebase'

export default function KioskPage() {
  const params = useParams()
  const queryParams = new URLSearchParams(window.location.search)
  const store = params.store as string
  const group = params.group as string
  const printerPortName = (queryParams.get('printer') as string | null) || null

  const [groupData, groupLoading] = useDocData(db.getGroupRef(store, group), [store, group])
  const [queuesData, queuesLoading] = useCollData(db.getQueuesRef(store), [store])

  if (groupLoading || queuesLoading) {
    return <Loader />
  }

  if (!groupData || !queuesData) {
    return <Error />
  }

  return <GroupKioskView
    store={store}
    group={group}
    groupData={groupData}
    queuesData={queuesData}
    printerPortName={printerPortName}
  />
}
