import { useEffect } from 'react'
import { Box } from '@chakra-ui/react'
import { motion, useAnimation } from 'framer-motion'
import { theme } from '@flowby/shared-general'

export default function UpdateValueAnimation({
  children,
  animateOnChange,
  defaultColor = '#FFFF',
}: {
  children: JSX.Element
  animateOnChange: any[]
  defaultColor?: string
}) {
  const controls = useAnimation()
  useEffect(() => {
    controls.start({
      scaleY: [1, 1.2, 1],
      scaleX: [1, 1.2, 1],
      backgroundColor: [theme.colors.colors.yellow[200], theme.colors.colors.yellow[200], defaultColor],
    })
  }, animateOnChange)

  return (
    <motion.div
      animate={controls}
      transition={{ ease: 'anticipate', duration: 0.8, delay: 0 }}
      style={{ borderRadius: '2rem', alignSelf: 'center' }}
    >
      <Box>{children}</Box>
    </motion.div>
  )
}
