import {
  Stack,
  FormControl,
  FormLabel,
  FormHelperText,
  Checkbox,
  Select
} from '@chakra-ui/react'
import { db, StoreData } from '@flowby/shared-firebase'
import { useToast } from '../../shared/Toast'

export default function Config({ storeData, store }: {
  storeData: StoreData
  store: string
}) {
  const toast = useToast()

  const onEditAutoEmptyQueues = async (time: number | null) => {
    const updateConfig = {
      'config.autoEmptyQueues': time
    }
    try {
      await db.updateStore(store, updateConfig)
      toast('success', 'Auto empty config updated.', 'StoreConfig/auto-empty-config-updated')
    } catch (e) {
      toast('error', null, 'StoreConfig/auto-empty-config-error')
    }
  }

  const isAutoEmptyQueuesEnabled =
    !(storeData.config?.autoEmptyQueues === null || storeData.config?.autoEmptyQueues === undefined)

  return (
    <Stack spacing={4} pb={2} overflow="hidden">
      <FormControl id="auto-close">
        <FormLabel>Auto Empty Queues</FormLabel>
        <FormHelperText>
          Your queues are emptied at a certain time each day after closing the store.
          This is to ensure no customers are left in the queue overnight. All queue numbers in
          the queue will be deleted and your customers will be redirected to the thank you page.
          The time is in the store's local time zone.
        </FormHelperText>
        <Checkbox
          pb={2}
          name="autoEmptyQueuesEnabled"
          isChecked={isAutoEmptyQueuesEnabled}
          onChange={e => {
            if (e.target.checked) {
              onEditAutoEmptyQueues(3)
            } else {
              onEditAutoEmptyQueues(null)
            }
          }}
        >
          Enable auto empty queues
        </Checkbox>
        {isAutoEmptyQueuesEnabled && (
          <Select
            value={storeData.config?.autoEmptyQueues || 0}
            onChange={e => onEditAutoEmptyQueues(Number(e.target.value))}
          >
            <option value={0}>00:00</option>
            <option value={1}>01:00</option>
            <option value={2}>02:00</option>
            <option value={3}>03:00</option>
            <option value={4}>04:00</option>
            <option value={5}>05:00</option>
            <option value={6}>06:00</option>
            <option value={7}>07:00</option>
            <option value={8}>08:00</option>
            <option value={9}>09:00</option>
            <option value={10}>10:00</option>
            <option value={11}>11:00</option>
            <option value={12}>12:00</option>
            <option value={13}>13:00</option>
            <option value={14}>14:00</option>
            <option value={15}>15:00</option>
            <option value={16}>16:00</option>
            <option value={17}>17:00</option>
            <option value={18}>18:00</option>
            <option value={19}>19:00</option>
            <option value={20}>20:00</option>
            <option value={21}>21:00</option>
            <option value={22}>22:00</option>
            <option value={23}>23:00</option>
          </Select>
        )}
      </FormControl>
    </Stack>
  )
}
