import { QuestionOutlineIcon } from '@chakra-ui/icons'
import { Tooltip as ChakraTooltip } from '@chakra-ui/react'

export const Tooltip = ({ label }: {
  label: string
}) => {
  return (
    <ChakraTooltip
      label={label}
      fontSize='md'
    >
      <QuestionOutlineIcon />
    </ChakraTooltip>
  )
}


