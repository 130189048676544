import { db } from '@flowby/shared-firebase'
import { useDocData } from '../../libs/firebaseHooks'
import { getTimeZoneDiffFromUTC, shouldWarnAutoEmptyQueues } from '../../libs/timezones'
import { useEffect, useState } from 'react'
import HighlightAlert from './HighlightAlert'
import { useDisclosure } from '@chakra-ui/react'
import { useToast } from './Toast'

export default function AlertAutoEmpty({ store }: { store: string }) {
  const toast = useToast()
  const notifyAboutNewCustomerDisclosure = useDisclosure()
  const [storeData] = useDocData(db.getStoreRef(store), [store])
  const [hasShownAlert, setHasShownAlert] = useState(false)

  const onTempDisableAutoEmpty = async () => {
    const updateConfig = {
      'config.tempDisableAutoEmptyQueue': true
    }
    try {
      await db.updateStore(store, updateConfig)
    } catch (e) {
      toast('error', null, 'StoreAdmin/edit-queue-auto-empty')
    }
    notifyAboutNewCustomerDisclosure.onClose()
  }

  const warnAutoEmptyStore = () => {
    if (!storeData || storeData.config?.autoEmptyQueues === null || storeData.config?.autoEmptyQueues === undefined) {
      return false
    }
    const now = new Date().getUTCHours()
    const timeZoneDiffFromUTC = getTimeZoneDiffFromUTC(storeData.country)
    if (timeZoneDiffFromUTC === null) {
      throw new Error('timeZoneDiffFromUTC not found for country')
    }
    const shouldWarn = shouldWarnAutoEmptyQueues(storeData.config.autoEmptyQueues, now, timeZoneDiffFromUTC)
    if (shouldWarn && !hasShownAlert) {
      setHasShownAlert(true)
      notifyAboutNewCustomerDisclosure.onOpen()
      Rollbar && Rollbar.warning('WarningAutoEmptyStore')
    }
  }

  setInterval(() => {
    warnAutoEmptyStore()
  }, 1000 * 60 * 60)

  useEffect(() => {
    warnAutoEmptyStore()
  }, [storeData])

  if (!storeData) {
    return null
  }

  return <HighlightAlert
    disclosure={notifyAboutNewCustomerDisclosure}
    bgColor={'yellow.200'}
    headerText={`The store is set to auto empty all queues at ${storeData.config?.autoEmptyQueues}`}
    descriptionText={`If you are still using the queue system, you can temporarily disable the auto empty functionality today by clicking the button below.
      You can also change the setting in the store settings.`}
    buttonOneText="Disable auto empty"
    buttonOneAction={() => { onTempDisableAutoEmpty() }}
    buttonTwoText="Ignore"
    buttonTwoAction={() => notifyAboutNewCustomerDisclosure.onClose()}
  />
}
