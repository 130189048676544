import { useToast as useChakraToast } from '@chakra-ui/toast'

export const useToast = () => {
  const toast = useChakraToast()

  const showToast = (
    type: 'success' | 'error' | 'warning' | 'info',
    title: string | null,
    origin?: string, // This is the calling component for better error logging
    error?: unknown
  ) => {
    if (title === null) {
      title = 'Sorry, something went wrong. Please try again or contact support at hello@flowby.io.'
    }
    // Report error to rollbar
    if (type === 'error') {
      if (error instanceof Error) {
        Rollbar.error(`${origin}: ${title}`, error)
      } else {
        Rollbar.error(`${origin}: ${title}`)
      }
    }

    toast({
      title,
      status: type,
      duration: 5000,
      position: 'top',
    })
  }

  return showToast
}
