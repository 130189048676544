import {
  Flex,
  Box,
  Stack,
  Heading,
  Container,
  Text,
} from '@chakra-ui/react'
import { User } from '@flowby/shared-firebase'
import { useEffect, useState } from 'react'
import { IoEnterOutline } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom'
import Header from '../components/shared/Header'
import Loader from '../components/shared/Loader'
import CreateStore from '../components/Signup/CreateStore'
import CreateUser from '../components/Signup/CreateUser'
import Success from '../components/Signup/Success'
import VerifyEmail from '../components/Signup/VerifyEmail'

export default function SignupPage({ user, userLoading, updateStore }: {
  user: User,
  userLoading: boolean,
  updateStore: () => void
}) {
  const navigate = useNavigate()
  const [claimStore, setClaimStore] = useState<string | null>(null)
  const [emailVerified, setEmailVerified] = useState(false)

  useEffect(() => {
    if (user && user.emailVerified) {
      setEmailVerified(true)
      user.getIdTokenResult(true).then(idTokenResult => {
        const claimStore = idTokenResult.claims.store as string
        if (claimStore) {
          setClaimStore(claimStore)
        }
      })
    }
  }, [user])

  let step = {
    label: 'Loading...',
    text: '',
    content: <Loader fullscreen={true} />
  }
  if (userLoading) {
    return step.content
  }

  if (!user) {
    step = {
      label: 'Create User 🚀',
      text: 'Sign up below to create a user and get started. You will be up and running in no time!',
      content: <CreateUser />
    }
  } else if (user && !emailVerified) {
    step = {
      label: 'Verify email 📬',
      text: 'We just need to verify you are the owner of the email you provided.',
      content: <VerifyEmail user={user} setEmailVerified={setEmailVerified} />
    }
  } else if (user && emailVerified && !claimStore) {
    step = {
      label: 'Create Store 🏠',
      text: 'Setup your store by filling in the info needed below.',
      content: <CreateStore setStore={(store: string) => {
        setClaimStore(store)
        updateStore()
      }} />
    }
  } else if (user && emailVerified && claimStore) {
    step = {
      label: 'Success 🎉',
      text: 'You are all set!',
      content: <Success />
    }
  }

  return (
    <Flex minH={'90vh'} flexDirection="column">
      <Header
        leftComponent={null}
        rightComponent={{
          text: 'Login',
          icon: <IoEnterOutline size={24} />,
          onClick: () => navigate('/login'),
        }}
      />
      <Container>
        <Stack spacing={6} mx={'auto'} maxW={'lg'} py={{ base: 4, md: 12 }}>
          <Stack spacing={4} textAlign={'center'}>
            <Heading fontSize={'4xl'}>{step.label}</Heading>
            <Text fontSize="lg">{step.text}</Text>
          </Stack>
          <Box
            rounded={'lg'}
            p={8}
            borderWidth="1px"
            bg="white"
            shadow="md"
            borderColor="gray.200"
            borderRadius="0.375rem"
          >
            <Stack spacing={4}>
              {step.content}
            </Stack>
          </Box>
        </Stack>
      </Container>
    </Flex>
  )
}
