import { useEffect, useState } from 'react'
import {
  Box,
  Stack,
  Button,
  FormControl,
  FormLabel,
  FormHelperText,
  Select,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Text,
  Badge,
  useDisclosure,
} from '@chakra-ui/react'
import { db, QueueData, StoreData, SupportedLangs } from '@flowby/shared-firebase'
import { translations } from '@flowby/shared-general'
import { useToast } from '../../shared/Toast'
import { findFieldsMissingTranslations, MissingTranslations } from './utils'
import AddMissingTranslationsForm from './AddMissingTranslationsForm'
import ModalContainer from '../../shared/ModalContainer'

export default function Languages({ storeData, queuesData, store }: {
  storeData: StoreData
  queuesData: QueueData[]
  store: string
}) {
  const [addLangSelect, setAddLangSelect] = useState<string | null>(null)
  const [missingTranslations, setMissingTranslations] = useState<MissingTranslations | null>(null)
  const toast = useToast()
  const addTranslationsDisclosure = useDisclosure()
  const availableLangs = Object.keys(translations)
  const supportedLangs: string[] = storeData.supportedLangs
  const notSupportedLangs = availableLangs.filter(lang => !supportedLangs.includes(lang))

  useEffect(() => {
    if (!addLangSelect) {
      setAddLangSelect(notSupportedLangs.length > 0 && notSupportedLangs[0] ? notSupportedLangs[0] : null)
    }
  })

  const onEditStoreData = async (data: { [key: string]: any }) => {
    try {
      await db.updateStore(store, data)
    } catch (e) {
      toast('error', null, 'StoreAdmin/edit-store-error')
    }
  }



  const onRemoveLang = async (lang: string) => {
    try {
      await db.removeSupportedLanguage(store, lang)
      toast('success', 'Language removed.', 'EditStoreForm/remove-lang-success')
      setAddLangSelect(null)
    } catch (e) {
      toast('error', null, 'EditStoreForm/remove-lang-error', e)
    }
  }

  const addLang = async () => {
    if (addLangSelect) {
      try {
        await db.addSupportedLanguage(store, addLangSelect)
        setAddLangSelect(null)
        toast('success', 'Language added.', 'EditStoreForm/add-lang-success')
      } catch (e) {
        toast('error', null, 'EditStoreForm/add-lang-error', e)
      }
    }
  }

  const onAddLang = async () => {
    if (addLangSelect) {
      const missingTranslations = findFieldsMissingTranslations(
        addLangSelect as SupportedLangs,
        storeData.lang,
        queuesData
      )
      if (missingTranslations.length > 0) {
        setMissingTranslations(missingTranslations)
        addTranslationsDisclosure.onOpen()
        return
      }
      addLang()
    }
  }

  return (
    <Stack spacing={4} pb={2} overflow="hidden">
      {
        missingTranslations && <ModalContainer
          isOpen={addTranslationsDisclosure.isOpen}
          onClose={addTranslationsDisclosure.onClose}
          header={`Add language: ${addLangSelect && (translations as any)[addLangSelect].name}`}
          content={
            <AddMissingTranslationsForm
              store={store}
              lang={addLangSelect}
              missingTranslations={missingTranslations}
              onFinish={() => {
                addTranslationsDisclosure.onClose()
                addLang()
              }}
            />
          }
        />
      }
      <FormControl id="languages">
        <FormLabel>Languages</FormLabel>
        <FormHelperText>
          Your customers will be able to choose between the languages added below when they enter your queue.
          The primary language will be chosen by default.
        </FormHelperText>
        <Box my={2} borderWidth={1} borderRadius={'md'} borderColor={'gray.200'}>
          <Table variant="unstyled">
            <Thead>
              <Tr borderBottomWidth={1}>
                <Th>Supported Languages</Th>
              </Tr>
            </Thead>
            <Tbody>
              {supportedLangs.map((key: string) => {
                const langName = (translations as any)[key].name
                return (
                  <Tr borderBottomWidth={1} key={key}>
                    <Td>
                      <Stack direction="row">
                        <Text>{langName}</Text>
                        {key === storeData.lang ? (
                          <Box>
                            <Badge
                              py={0.5}
                              px={2}
                              rounded="md"
                              size="xs"
                              colorScheme="blue"
                            >
                              Primary
                            </Badge>
                          </Box>
                        ) :
                          (
                            <Button
                              variant="outline"
                              size="xs"
                              colorScheme="blue"
                              onClick={() => {
                                onEditStoreData({ lang: key })
                              }}
                            >
                              Make primary
                            </Button>
                          )}
                        <Button
                          variant="outline"
                          size="xs"
                          colorScheme="red"
                          onClick={() => {
                            if (supportedLangs.length <= 1) {
                              toast('error', 'You need to have at least one language.')
                              return
                            }
                            if (key === storeData.lang) {
                              toast('error', 'You cannot remove the primary language. Please change the primary language first.')
                              return
                            }
                            onRemoveLang(key)
                          }}
                        >
                          Remove
                        </Button>
                      </Stack>
                    </Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        </Box>
        <Stack direction="row" alignItems="center">
          <Select
            isDisabled={notSupportedLangs.length <= 0}
            onChange={e => setAddLangSelect(e.target.value)}
            name="addLangToGroup"
          >
            {notSupportedLangs.map(langKey => {
              return (
                <option key={langKey} value={langKey}>
                  {(translations as any)[langKey].name}
                </option>
              )
            })}
            {notSupportedLangs.length <= 0 && <option value={undefined}>
              You have added all currently supported languages. Contact us if you need another language.
            </option>}
          </Select>
          <Button size="md" isDisabled={notSupportedLangs.length <= 0} onClick={() => onAddLang()}>
            Add
          </Button>
        </Stack>
      </FormControl>
    </Stack>
  )
}
