import { useState } from 'react'
import { Stack, Button, FormControl, FormLabel, Input, Select } from '@chakra-ui/react'
import { db } from '@flowby/shared-firebase'
import { useToast } from '../shared/Toast'

export default function SetUserAccessForm({ users, onFinish }: { users: any[]; onFinish: () => void }) {
  const [userId, setUserId] = useState('')
  const [store, setStore] = useState('')
  const [loading, setLoading] = useState(false)
  const toast = useToast()

  const setUserClaim = async () => {
    setLoading(true)
    try {
      await db.adminSetUserClaim({ userId: userId, claim: { store: store } })
      onFinish()
      setLoading(false)
    } catch (e) {
      toast('error', null, 'SetUserAccessForm/set-user-claim-error', e)
      setLoading(false)
    }
  }

  return (
    <Stack spacing={4}>
      <FormControl id="userId">
        <FormLabel>Email</FormLabel>
        <Select onChange={e => setUserId(e.target.value)} placeholder="Select option">
          {users.sort(
            (a, b) => (a.email > b.email) ? 1 : ((b.email > a.email) ? -1 : 0)
          ).map(user => {
            return <option value={user.uid}>{user.email}</option>
          })}
        </Select>
      </FormControl>
      <FormControl id="userStore">
        <FormLabel>Store ID</FormLabel>
        <Input name="userStore" type="text" value={store} onChange={e => setStore(e.target.value)} />
      </FormControl>
      <Stack spacing={5}>
        <Button isLoading={loading} onClick={setUserClaim}>
          Give user access
        </Button>
      </Stack>
    </Stack>
  )
}
