import { Text } from '@chakra-ui/react'
import { QueueNumberData } from '@flowby/shared-firebase'
import UpdateValueAnimation from '../shared/UpdateValueAnimation'
import { useEffect, useState } from 'react'

const diffMinutes = (dt2: Date, dt1: Date) => {
  const diff = (dt2.getTime() - dt1.getTime()) / 1000 / 60
  return Math.abs(Math.round(diff))
}

export default function WaitTime({
  fontSize = 'md',
  queueNumbersData,
}: {
  fontSize?: 'sm' | 'md' | 'lg'
  queueNumbersData: QueueNumberData[] | undefined
}) {
  const [now, setNow] = useState(new Date())

  useEffect(() => {
    const timer = setInterval(() => {
      setNow(new Date())
    }, 10000)
    return () => clearInterval(timer)
  }, [])

  const waitTime =
    queueNumbersData &&
    queueNumbersData[0] &&
    queueNumbersData[0].created &&
    diffMinutes(queueNumbersData[0].created.toDate(), now)

  return (
    <UpdateValueAnimation animateOnChange={[waitTime]}>
      <Text fontSize={fontSize} px={1}>{waitTime !== undefined ? `${waitTime} min` : '-'}</Text>
    </UpdateValueAnimation>
  )
}
