import { 
  Timestamp as firestoreTimestamp,
  FieldValue as firestoreFieldValue,
  CollectionReference,
  DocumentReference,
  FirestoreError,
  Query
} from 'firebase/firestore'
import { User as firebaseUser } from 'firebase/auth'

export type SupportedLangs = 'en' | 'sv' | 'no' | 'fi'
export const supportedLangs: SupportedLangs[] = ['en', 'sv', 'no', 'fi']
export type Timestamp = firestoreTimestamp

export type StoreConfig = {
  status: boolean | null
  autoEmptyQueues: number | null // 0-23 for the hour in store's local time zone
  tempDisableAutoEmptyQueue: boolean | null
}

export type StoreData = {
  name: string
  status: string
  country: string
  lang: SupportedLangs
  supportedLangs: SupportedLangs[]
  config?: StoreConfig
}

// there is a possiblity that we lack queue number yet when the user comes from the data input page
// then the queue number will only have the phoneSet and customDataSet fields set
// this is because we create the queue number object when the user has filled in the data
// but before the user has been assigned a queue number
export type QueueNumberData = {
  queueNumber: number | undefined
  manual: boolean | undefined
  phoneSet: boolean | undefined // undefined means not set, which will prompt the user to set it
  customDataSet?: boolean | undefined
  created?: firestoreTimestamp | undefined
}

export type PiiPhoneData = {
  language: SupportedLangs
  created: firestoreFieldValue
  phoneNumber: string
  queue: string
}

export type PiiCustomData = {
  created: firestoreFieldValue
  data: {
    [key: string]: string
  }
}

export type QueueState = {
  status: string
  count: number
  currentNumber: number
  nextNumber: number
  currentNumberID: string | null
  currentNumberData: {
    manual: boolean
  } | null
  currentNumberServed: firestoreTimestamp | null
}

export type QueueTranslations = {
  [Property in SupportedLangs]?: {
    yourTurn: string
  }
}

export type QueueConfig = {
  notifyWhenTurnsUntilMyTurn?: number
  queueIsUnordered?: boolean
  estimatedThroughput?: number | null
  keepMyTurnOnThanksPage?: boolean
}

export type DataField = {
  [Property in SupportedLangs]: {
    // type: 'textInput' | removed field, did not make sense
    name: string
    description: string
    placeholder?: string
  }
}

export type DataFields = {
  [key: string]: DataField & { required?: boolean } // field id
}

export type QueueData = {
  displayName: string
  shortName: string
  state: QueueState
  translations: QueueTranslations
  config?: QueueConfig
  customDataFields?: DataFields & firestoreFieldValue
}

export type GroupData = {
  displayName: string
  shortName: string
  queues: string[]
}

export type ReviewData = {
  rating: number | null
  message: string | null
  store: string
  queue: string
  queuer_id: string | null
  device_vendor: string | null
  device_os: string | null
  device_model: string | null
  browser: string | null
  screen_width: number | null
  screen_height: number | null
  created_at?: Date | null
  date?: string | null
}

export type AnalyticsData = {
  store: string
  queue: string
  queuer_id: string
  action: string
  queue_count: number | null
  queue_number: number | null
  customer_number: number
  manual: boolean
  date: { value: string }
  when: { value: string }
  kiosk: boolean | null
}

export type QueriedAnalyticsData = {
  queue: string
  queuer_id: string
  action: string
  manual: boolean
  date: { value: string }
  when: { value: string }
  kiosk: boolean | null
}

export type User = firebaseUser
export type CollRef<T> = CollectionReference<T> | Query<T>
export type DocRef<T> = DocumentReference<T>
export type FireStoreError = FirestoreError