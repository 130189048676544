import { CollRef, db, DocRef, FireStoreError, auth, firebase, User } from "@flowby/shared-firebase"
import { useEffect, useState } from "react"
import useLoadingValue from './useLoadingValue'

export function useDocData<T>(ref: DocRef<T>, deps: any[]) {
  const [data, setData] = useState<T | undefined>(undefined)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<FireStoreError | undefined>(undefined)
  useEffect(() => {
    setData(undefined)
    setLoading(true)
    setError(undefined)
    const unsubscribe = db.onChange(ref, (data) => {
      setData(data.data())
      setLoading(false)
      setError(undefined)
    }, (error) => {
      setError(error)
      setLoading(false)
    })
    return unsubscribe
  }, deps)
  const r: [T | undefined, boolean, FireStoreError | undefined] = [data, loading, error]
  return r
}

export function useCollData<T>(ref: CollRef<T>, deps: any[]) {
  const [data, setData] = useState<T[] | undefined>(undefined)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<FireStoreError | undefined>(undefined)
  useEffect(() => {
    setData(undefined)
    setLoading(true)
    setError(undefined)
    const unsubscribe = db.onChange(ref, (data) => {
      setData(data.docs.map((doc) => doc.data()))
      setLoading(false)
      setError(undefined)
    }, (error) => {
      setError(error)
      setLoading(false)
    })
    return unsubscribe
  }, deps)
  const r: [T[] | undefined, boolean, FireStoreError | undefined] = [data, loading, error]
  return r
}

type AuthStateHook = LoadingHook<User | null, Error>;

type AuthStateOptions = {
  onUserChanged?: (user: User | null) => Promise<void>;
};

type LoadingHook<T, E> = [T | undefined, boolean, E | undefined];

export function useAuthState(options?: AuthStateOptions): AuthStateHook {
  const { error, loading, setError, setValue, value } = useLoadingValue<
    User | null,
    Error
  >(() => firebase.auth.currentUser)

  useEffect(() => {
    const listener = firebase.auth.onAuthStateChanged(
      async (user) => {
        if (options?.onUserChanged) {
          // onUserChanged function to process custom claims on any other trigger function
          try {
            await options.onUserChanged(user)
          } catch (e) {
            setError(e as Error)
          }
        }
        setValue(user)
      },
      setError
    )

    return () => {
      listener()
    }
  }, [auth])

  return [value, loading, error]
}