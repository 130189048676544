import { Stack, Flex } from '@chakra-ui/react'
import Footer from './Footer'
import Header from './Header'

export default function PageLayout({
  children,
  leftNavigation,
  logo,
  rightNavigation,
}: {
  children: JSX.Element
  leftNavigation: {
    text: string
    icon: JSX.Element
    onClick: () => void
  } | null
  logo?: JSX.Element
  rightNavigation: {
    text: string
    icon: JSX.Element
    onClick: () => void
  } | null
}) {
  return (
    <Stack minH="100%">
      <Header leftComponent={leftNavigation} logo={logo} rightComponent={rightNavigation} />
      <Flex direction="column" flex={1} pt={{ md: 2 }}>
        {children}
      </Flex>
      <Footer />
    </Stack>
  )
}
