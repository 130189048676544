import { useBreakpointValue } from "@chakra-ui/react"
import { Capacitor } from '@capacitor/core'
import { StatusBar, Style } from '@capacitor/status-bar'

export const setDefaultStatusBar = () => {
  try {
    if (Capacitor.isPluginAvailable('StatusBar')) {
      StatusBar.setStyle({ style: Style.Light })
      // Display content under transparent status bar (Android only)
      if (Capacitor.getPlatform() === 'android') {
        StatusBar.setOverlaysWebView({ overlay: false }) // Android only
        StatusBar.setBackgroundColor({ color: '#ffffff' }) // Android only
      }
    }
  } catch (e) {
    Rollbar.error(`utils: setDefaultStatusBar`, JSON.stringify(e))
  }
}

export const hideStatusBar = () => {
  try {
    if (Capacitor.isPluginAvailable('StatusBar')) {
      StatusBar.hide()
    }
  } catch (e) {
    Rollbar.error(`utils: hideStatusBar`, JSON.stringify(e))
  }
}

export const useScreenSize = () => {
  const size = useBreakpointValue<'mobile' | 'tablet' | 'desktop'>({ base: 'mobile', md: 'tablet', lg: 'desktop' })
  return size || 'mobile'
}

export const isNative = () => {
  try {
    if (Capacitor.isNativePlatform()) {
      return true
    } else {
      return false
    }
  } catch (e) {
    Rollbar.error(`utils: isNative`, JSON.stringify(e))
    return
  }
}