import { useState } from 'react'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { Box, Stack, Button, Image, Heading, Text, Link, Container, useDisclosure } from '@chakra-ui/react'
import PageLayout from '../components/shared/PageLayout'
import { firebase, db } from '@flowby/shared-firebase'
import { IoExitOutline, IoStorefrontOutline, IoOpenOutline, IoTabletPortraitSharp } from 'react-icons/io5'
import QueueList from '../components/QueueList/QueueList'
import Loader from '../components/shared/Loader'
import { useCollData, useDocData } from '../libs/firebaseHooks'
import { useScreenSize } from '../libs/native'
import ModalContainer from '../components/shared/ModalContainer'
import KioskGuide from '../components/Kiosk/KioskGuide'

export default function StorePage() {
  const kioskDisclosure = useDisclosure()
  const screenSize = useScreenSize()
  const { store } = useOutletContext<{ store: string }>()
  const navigate = useNavigate()
  const [storeData] = useDocData(db.getStoreRef(store), [store])
  const [queuesData, queuesLoading] = useCollData(
    db.getQueuesRef(store), [store]
  )
  const [groupsData, groupsLoading] = useCollData(db.getGroupsRef(store), [store])
  const [logoError, setLogoError] = useState(false)

  // TODO: Handle store data error etc
  // Should show error message
  return (
    <>
      <ModalContainer
        isOpen={kioskDisclosure.isOpen}
        onClose={kioskDisclosure.onClose}
        header="Kiosk Mode"
        content={
          groupsData && queuesData ?
            <KioskGuide
              store={store}
              groupsData={groupsData}
              queuesData={queuesData}
            /> : <Box></Box>
        }
      />
      <PageLayout
        leftNavigation={{
          text: 'Logout',
          icon: <IoExitOutline style={{ transform: 'scaleX(-1)' }} size={24} />,
          onClick: () => {
            firebase.auth.signOut()
            navigate('/')
          },
        }}
        rightNavigation={{
          text: 'Admin',
          icon: <IoStorefrontOutline size={24} />,
          onClick: () => navigate(`/${store}/admin`),
        }}
      >
        <Container>
          <Stack spacing={4}>
            <Stack align="center" textAlign="center">
              {logoError ? (
                <Heading size="2xl">{storeData && storeData.name}</Heading>
              ) : (
                <Box position="relative" w="100%" h={70} textAlign="center" alignItems="center">
                  {!logoError && (
                    <Image
                      margin="auto"
                      maxH={70}
                      fit="contain"
                      src={`https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_ENV === 'dev' ? 'flowby-io-dev' : 'flowby-io'}.appspot.com/o/stores%2F${store}%2Flogo.png?alt=media`}
                      onError={() => setLogoError(true)}
                    />
                  )}
                </Box>
              )}
              {screenSize === 'desktop' && (
                <Button
                  as={Link}
                  isExternal
                  href={`${process.env.REACT_APP_ENV === 'dev' ? 'https://dev.go.flowby.io' : 'https://go.flowby.io'}/${store}/status`}
                  variant="ghost"
                  size="sm"
                  rightIcon={<IoOpenOutline />}
                >
                  Status
                </Button>
              )}
              {screenSize === 'tablet' && (
                <Button
                  onClick={kioskDisclosure.onOpen}
                  variant="ghost"
                  size="sm"
                  rightIcon={<IoTabletPortraitSharp />}
                >
                  Kiosk
                </Button>
              )}

            </Stack>

            {(queuesLoading || groupsLoading) && <Loader />}
            {queuesData && groupsData && storeData && (
              <Box>
                <QueueList store={store} queuesData={queuesData} groupsData={groupsData} />
              </Box>
            )}
            {queuesData && queuesData.length === 0 && (
              <Stack align="center" spacing={4}>
                <Text textAlign="center">
                  Create your first queue by clicking 'Admin' in the top right corner or clicking the button below.
                </Text>
                <Box>
                  <Button onClick={() => navigate(`/${store}/admin`)}>Open store admin</Button>
                </Box>
              </Stack>
            )}
          </Stack>
        </Container>
      </PageLayout>
    </>
  )
}
