import { useRef } from 'react'
import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
  Flex,
  Stack,
} from '@chakra-ui/react'

export default function HighlightAlert({
  disclosure,
  bgColor,
  headerText,
  descriptionText,
  buttonOneText,
  buttonOneAction,
  buttonTwoText,
  buttonTwoAction
}: {
  disclosure: {
    isOpen: boolean
    onOpen: () => void
    onClose: () => void
    onToggle: () => void
    isControlled: boolean
    getButtonProps: (props?: unknown) => unknown
    getDisclosureProps: (props?: unknown) => unknown
  }
  bgColor: string
  headerText: string
  descriptionText: string
  buttonOneText?: string
  buttonOneAction?: () => void
  buttonTwoText?: string
  buttonTwoAction?: () => void
}) {
  const noRef = useRef(null)
  return (
    <AlertDialog
      isOpen={disclosure.isOpen}
      onClose={disclosure.onClose}
      leastDestructiveRef={noRef}
      size="2xl"
      isCentered
    >
      <AlertDialogOverlay>
        <AlertDialogContent p={8} bgColor={bgColor}>
          <Stack>
            <AlertDialogHeader textAlign="center" fontSize="2xl" fontWeight="bold">
              {headerText}
            </AlertDialogHeader>

            <AlertDialogBody fontSize="xl" textAlign="center">
              {descriptionText}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Flex flex={1} justify="center" direction="column">
                {buttonOneText && <Button ref={noRef} onClick={buttonOneAction}>
                  {buttonOneText}
                </Button>}
                {buttonTwoText && <Button variant="ghost" ref={noRef} onClick={buttonTwoAction}>
                  {buttonTwoText}
                </Button>}
              </Flex>
            </AlertDialogFooter>
          </Stack>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}
