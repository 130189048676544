import {
  Stack,
  Box,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useDisclosure,
  Spinner,
  Center,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Stat,
  StatLabel,
  StatNumber,
  StatGroup
} from '@chakra-ui/react'
import { db, StoreData } from '@flowby/shared-firebase'
import { useEffect, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import ErrorComponent from '../shared/Error'
import Loader from '../shared/Loader'
import ModalContainer from '../shared/ModalContainer'
import CreateStoreForm from './CreateStoreForm'
import { IoOpenOutline, IoTrashBinOutline } from 'react-icons/io5'
import { ChevronDownIcon } from '@chakra-ui/icons'
import ConfirmAlert from '../shared/ConfirmAlert'
import { useToast } from '../shared/Toast'
import { useCollData } from '../../libs/firebaseHooks'
import { useScreenSize } from '../../libs/native'

const mapStatusToNumber = (status: string) => {
  if (status === 'active') {
    return 3
  } else if (status === 'pending') {
    return 2
  } else if (status === 'inactive') {
    return 1
  } else {
    return 0
  }
}

const orderStores = (storeA: StoreData, storeB: StoreData) => {
  return mapStatusToNumber(storeB.status) - mapStatusToNumber(storeA.status)
}

export default function Stores() {
  const screenSize = useScreenSize()
  const createStoreDisclosure = useDisclosure()
  const deleteStoreDisclosure = useDisclosure()
  const toast = useToast()
  const [storesData, storesDataLoading] = useCollData(db.getStoresRef(), [])
  const [queueCounts, setQueueCounts] = useState<{ [key: string]: number } | undefined>(undefined)
  const [deleteStoreLoading, setDeleteStoreLoading] = useState<boolean>(false)
  const [selectedStoreId, setSelectedStoreId] = useState<string | undefined>(undefined)

  useEffect(() => {
    if (storesData && !queueCounts) {
      const stores = storesData.map(store => {
        return store.id
      })
      getQueueCounts(stores).then(queueCounts => {
        setQueueCounts(queueCounts)
      })
    }
  }, [storesData])

  const deleteStore = async () => {
    setDeleteStoreLoading(true)
    try {
      if (!selectedStoreId) {
        throw new Error('No store selected.')
      }
      await db.deleteStore(selectedStoreId)
      toast('success', `Deleted store with id ${selectedStoreId}.`)
      deleteStoreDisclosure.onClose()
    } catch (e) {
      toast('error', `Failed to delete store with id ${selectedStoreId}.`, 'FlowbyAdmin/delete-store-error', e)
    }
    setDeleteStoreLoading(false)
  }

  const editStoreStatus = async (storeId: string, status: string) => {
    try {
      toast('success', `Store status changed to ${status}.`)
      await db.updateStore(storeId, { status })
    } catch (e) {
      toast('error', `Failed to change store status to ${status}.`, 'FlowbyAdmin/edit-store-status-error', e)
    }
  }

  const mapStatus = (status: string) => {
    if (status === 'active') {
      return 'green.500'
    } else if (status === 'pending') {
      return 'yellow.500'
    } else if (status === 'inactive') {
      return 'red.500'
    } else {
      return 'gray.500'
    }
  }

  const getQueueCounts = async (stores: string[]) => {
    const counts: { [key: string]: number } = {}
    const storeCounts = stores.map(async store => {
      const queuesData = await db.getQueues(store)
      return counts[store] = queuesData.size
    })
    await Promise.all(storeCounts)
    return counts
  }

  if (storesDataLoading) {
    return <Loader />
  }

  if (!storesData) {
    return <ErrorComponent />
  }

  const activeStores = storesData.filter(store => store.status === 'active')
  const activeQueues = activeStores
    .map(store => queueCounts && queueCounts[store.id])
    .filter(value => value !== undefined)
    .reduce((partialSum, a) => {
      const sum = partialSum as number
      const acc = a as number
      return sum + acc
    }, 0)
  return (
    <>
      <ModalContainer
        isOpen={createStoreDisclosure.isOpen}
        onClose={createStoreDisclosure.onClose}
        header="Create new store"
        content={<CreateStoreForm onFinish={createStoreDisclosure.onClose} />}
      />
      <ConfirmAlert
        disclosure={deleteStoreDisclosure}
        headerText="Delete store"
        questionText={`Are you sure you want to delete the store with id ${selectedStoreId}?`}
        noText="No"
        yesText="Yes"
        yesAction={deleteStore}
        yesLoading={deleteStoreLoading}
        validateString={selectedStoreId}
        validatePlaceholder="Type the store id to confirm deletion."
      />
      <Stack spacing={2}>
        <StatGroup>
          <Stat>
            <StatLabel>Active Stores</StatLabel>
            <StatNumber>{activeStores.length}</StatNumber>
          </Stat>
          <Stat>
            <StatLabel>Active Queues</StatLabel>
            <StatNumber>{activeQueues}</StatNumber>
          </Stat>
        </StatGroup>
        <Box>
          <Button size="md" onClick={createStoreDisclosure.onOpen}>
            Create new store
          </Button>
        </Box>
        {storesData && screenSize === 'desktop' && (
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Name</Th>
                <Th>ID</Th>
                <Th>Country</Th>
                <Th>Language</Th>
                <Th>Queues</Th>
                <Th>Status</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {storesData.sort(orderStores).map(store => {
                return (
                  <Tr key={store.id}>
                    <Td>{store.name}</Td>
                    <Td>{store.id}</Td>
                    <Td>{store.country}</Td>
                    <Td>{store.lang}</Td>
                    <Td>{queueCounts && queueCounts[store.id] !== undefined ? queueCounts[store.id] : <Spinner />}</Td>
                    <Td>
                      <Center>
                        <Menu>
                          <MenuButton size="sm" variant="outline" as={Button} rightIcon={<ChevronDownIcon />}>
                            <Box w={4} h={4} borderRadius="xl" backgroundColor={mapStatus(store.status)}></Box>
                          </MenuButton>
                          <MenuList>
                            <MenuItem onClick={() => editStoreStatus(store.id, 'active')}>
                              <Box w={4} h={4} mr={1} borderRadius="xl" backgroundColor="green.500"></Box>Active
                            </MenuItem>
                            <MenuItem onClick={() => editStoreStatus(store.id, 'pending')}>
                              <Box w={4} h={4} mr={1} borderRadius="xl" backgroundColor="yellow.500"></Box>Pending
                            </MenuItem>
                            <MenuItem onClick={() => editStoreStatus(store.id, 'inactive')}>
                              <Box w={4} h={4} mr={1} borderRadius="xl" backgroundColor="red.500"></Box>Inactive
                            </MenuItem>
                          </MenuList>
                        </Menu>
                      </Center>
                    </Td>
                    <Td>
                      <Stack direction="row">
                        <IconButton
                          colorScheme="red"
                          size="sm"
                          aria-label="Visit"
                          icon={<IoTrashBinOutline />}
                          onClick={() => {
                            setSelectedStoreId(store.id)
                            deleteStoreDisclosure.onOpen()
                          }}
                        />
                        <RouterLink to={`/${store.id}`} target="_blank">
                          <IconButton size="sm" aria-label="Visit" icon={<IoOpenOutline />} />
                        </RouterLink>
                      </Stack>
                    </Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        )}
        {storesData && (screenSize === 'mobile' || screenSize === 'tablet') && (
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Name</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {storesData.sort(orderStores).map(store => {
                return (
                  <Tr key={store.id}>
                    <Td>{store.name}</Td>
                    <Td>
                      <RouterLink to={`/${store.id}`}>
                        <IconButton size="sm" aria-label="Visit" icon={<IoOpenOutline />} />
                      </RouterLink>
                    </Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        )}
      </Stack>
    </>
  )
}
