import { useEffect, useRef, useState } from 'react'
import ModalContainer from '../shared/ModalContainer'
import StoreManualNumber from './StoreManualNumber'
import { Container, Stack, Button, useDisclosure, Divider } from '@chakra-ui/react'
import { db, GroupData, QueueData, resolveQueueNumberWithPrefix } from '@flowby/shared-firebase'
import QueuesInSameGroup from './QueuesInGroup'
import HighlightAlert from '../shared/HighlightAlert'
import { useToast } from '../shared/Toast'
import QueueInfo from './QueueInfo'

function usePrevious(value: any) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value //assign the value of ref to the argument
  }, [value]) //this code will run when the value of 'value' changes
  return ref.current //in the end, return the current ref value.
}

export default function QueueView({
  store,
  queue,
  queueData,
  groupsData
}: {
  store: string
  queue: string
  queueData: QueueData
  groupsData: GroupData[] | undefined
}) {
  const manualNumberDisclosure = useDisclosure()
  const notifyAboutNewCustomerDisclosure = useDisclosure()
  const toast = useToast()
  const [nextCustomerDisabled, setNextCustomerDisabled] = useState(false)
  const [manualQueueNumber, setManualQueueNumber] = useState<number | null>(null)
  async function getManual(store: string, queue: string) {
    setManualQueueNumber(null)
    const { queueNumber } = await db.createQueueNumber(store, queue, true, true)
    setManualQueueNumber(queueNumber)
  }

  useEffect(() => {
    document.title = `Flowby | ${queueData.displayName}`
  }, [])

  const audioRef = useRef<HTMLAudioElement>(null)
  const playSound = () => {
    if (audioRef && audioRef.current) {
      audioRef.current.play().catch(() => console.log('autoplay audio not allowed'))
    }
  }

  if (
    usePrevious(queueData.state.count) === 0 &&
    queueData.state.count === 1 &&
    !notifyAboutNewCustomerDisclosure.isOpen &&
    !manualNumberDisclosure.isOpen
  ) {
    playSound()
    notifyAboutNewCustomerDisclosure.onOpen()
  }

  async function callNextCustomer() {
    setNextCustomerDisabled(true)
    if (queueData.state.count === 0) {
      toast('warning', 'The queue is empty.')
      setTimeout(() => setNextCustomerDisabled(false), 1000)
      return
    }

    try {
      await db.takeNextCustomer(store, queue)
    } catch (error) {
      toast('error', 'Failed to take next customer. Try again.', 'QueueView/take-next-customer-error', error)
    }
    setTimeout(() => setNextCustomerDisabled(false), 1000)
  }

  return (
    <>
      <audio ref={audioRef}>
        <source src="/resources/household_doorbell_chime_bell.mp3" type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
      <Stack flex={1} justify="space-between" align="center">
        <ModalContainer
          isOpen={manualNumberDisclosure.isOpen}
          onClose={manualNumberDisclosure.onClose}
          header="Manual Queue Number"
          content={<StoreManualNumber
            manualQueueNumber={manualQueueNumber ? resolveQueueNumberWithPrefix(manualQueueNumber, queueData, groupsData) : '...'}
          />}
          footer={
            <Container pb={2}>
              <Divider />
              <Button size="md" mt={2} w="100%" variant="outline" onClick={manualNumberDisclosure.onClose}>
                Close
              </Button>
            </Container>
          }
        />
        <HighlightAlert
          disclosure={notifyAboutNewCustomerDisclosure}
          bgColor={'yellow.200'}
          headerText="New Customer!"
          descriptionText='A new customer entered your empty queue! Click the "Next Customer" button to serve them.'
          buttonOneText="Okay!"
          buttonOneAction={notifyAboutNewCustomerDisclosure.onClose}
        />
        <Container>
          <QueuesInSameGroup store={store} queue={queue} groupsData={groupsData} />
        </Container>
        <Container px={2}>
          <QueueInfo
            store={store}
            queue={queue}
            queueData={queueData}
            groupsData={groupsData}
          />
        </Container>
        <Container px={2}>
          <Stack alignItems="center" justifyContent="flex-end">
            <Button
              variant="outline"
              height={20}
              fontSize={26}
              w="100%"
              maxW="xl"
              onClick={() => {
                getManual(store, queue)
                manualNumberDisclosure.onOpen()
              }}
              borderWidth={2}
            >
              MANUAL NUMBER
            </Button>
            <Button
              height={20}
              fontSize={26}
              maxW="xl"
              w="100%"
              onClick={callNextCustomer}
              disabled={nextCustomerDisabled}
            >
              NEXT CUSTOMER
            </Button>
          </Stack>
        </Container>
      </Stack>
    </>
  )
}
