import { GroupData, QueueData } from "../types"

const groupOnePrefixes = ['A', 'B', 'C', 'D', 'E']
const groupTwoPrefixes = ['F', 'G', 'H', 'I', 'J']
const groupThreePrefixes = ['K', 'L', 'M', 'N', 'O']
const groupFourPrefixes = ['P', 'Q', 'R', 'S', 'T']
const groupFivePrefixes = ['U', 'V', 'W', 'X', 'Y']

const groupPrefixes = [groupOnePrefixes, groupTwoPrefixes, groupThreePrefixes, groupFourPrefixes, groupFivePrefixes]

export const resolveQueueNumberWithPrefix = (
  queueNumber: number | undefined,
  queueData: QueueData | undefined,
  groupsData: GroupData[] | undefined
) => {
  // this is a fallback for when the queue number is not yet loaded
  if (!queueNumber || !queueData) {
    return '-'
  }
  const inGroup = groupsData && groupsData.find(group => group.queues.includes(queueData.shortName))
  if (!inGroup) return `${queueNumber}`
  const groupIndex = groupsData && groupsData.indexOf(inGroup)
  return `${groupPrefixes[groupIndex][inGroup.queues.indexOf(queueData.shortName)]}${queueNumber}`
}


// can be used in filters to make typescript understand we are filtering out null or undefined values
export const isNotNull = <T>(x: T | null | undefined): x is T => !!x