import { Stack } from '@chakra-ui/react'
import { db } from '@flowby/shared-firebase'
import FormikForm from '../shared/FormikForm'
import { useToast } from '../shared/Toast'

export default function CreateQueueForm({ onFinish }: { onFinish: () => void }) {
  const toast = useToast()
  const createUser = async (data: { [key: string]: string }) => {
    try {
      if (!data.email || !data.password) {
        throw new Error('Missing data when creating store.')
      }
      await db.adminCreateUser({ email: data.email, password: data.password })
      toast('success', 'User created.')
      onFinish()
    } catch (e) {
      toast('error', null, 'AdminCreateUserForm/create-user-error', e)
    }
  }

  return (
    <Stack spacing={4}>
      <FormikForm
        fields={[
          {
            type: 'textInput',
            id: 'email',
            label: 'Email',
            validation: (value: string) => {
              if (!value || value.length === 0) {
                return 'Email is required.'
              }
              return undefined
            }
          },
          {
            type: 'textInput',
            id: 'password',
            label: 'Password',
            validation: (value: string) => {
              if (!value || value.length === 0) {
                return 'Password is required.'
              }
              if (value.length < 6) {
                return 'Password is too short.'
              }
              return undefined
            }
          }
        ]}
        onSubmit={createUser}
        submitButtonText="Create User"
      />
    </Stack>
  )
}
