import { useNavigate, useOutletContext } from 'react-router-dom'
import { useDisclosure } from '@chakra-ui/react'
import PageLayout from '../components/shared/PageLayout'
import { IoArrowBackSharp, IoHelpBuoyOutline } from 'react-icons/io5'
import StoreAdmin from '../components/StoreAdmin/StoreAdmin'
import Loader from '../components/shared/Loader'
import ModalContainer from '../components/shared/ModalContainer'
import { db } from '@flowby/shared-firebase'
import { useCollData, useDocData } from '../libs/firebaseHooks'
import Support from '../components/shared/Support'

export default function StoreAdminPage() {
  const { store } = useOutletContext<{ store: string }>()
  const supportDisclosure = useDisclosure()
  const navigate = useNavigate()
  const [storeData] = useDocData(db.getStoreRef(store), [store])
  const [queuesData, queuesLoading] = useCollData(db.getQueuesRef(store), [store])
  const [groupsData, groupsLoading] = useCollData(db.getGroupsRef(store), [store])

  // TODO: Handle store data error etc
  // Should show error message
  return (
    <PageLayout
      leftNavigation={{
        text: 'Back',
        icon: <IoArrowBackSharp size={24} />,
        onClick: () => navigate(`/${store}`),
      }}
      rightNavigation={{
        text: 'Support',
        icon: <IoHelpBuoyOutline size={24} />,
        onClick: supportDisclosure.onOpen,
      }}
    >
      <>
        <ModalContainer
          isOpen={supportDisclosure.isOpen}
          onClose={supportDisclosure.onClose}
          header="Support"
          content={<Support />}
        />
        {(queuesLoading || groupsLoading) && <Loader />}
        {queuesData && groupsData && storeData && (
          <StoreAdmin store={store} storeData={storeData} queuesData={queuesData} groupsData={groupsData} />
        )}
      </>
    </PageLayout>
  )
}
