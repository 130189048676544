import { useOnlineStatus } from '../../libs/connection'
import { ToastId, useToast as useChakraToast } from '@chakra-ui/toast'
import { useEffect, useRef, useState } from 'react'

export default function OfflineToast() {
  const toast = useChakraToast()
  const online = useOnlineStatus()
  const toastRef = useRef<ToastId>()
  const [showingToast, setShowingToast] = useState(false)

  useEffect(() => {
    if (online && showingToast) {
      setShowingToast(false)
      if (toastRef.current) {
        toast.close(toastRef.current)
      }
      toast({
        title: 'You are now online again.',
        status: 'success',
        duration: 3000,
        position: 'top',
      })
    }
    if (!online && !showingToast) {
      setShowingToast(true)
      toastRef.current = toast({
        title: 'You are currently offline. Please check your internet connection.',
        status: 'warning',
        duration: null,
        position: 'top',
      })
    }
  }, [online, showingToast])
  return null
}