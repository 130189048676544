import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import Plausible from 'plausible-tracker'
import posthog from 'posthog-js'
import './style.css'

const plausible = Plausible({
  domain: `${process.env.REACT_APP_ENV === 'dev' ? 'dev.admin.flowby.io' : 'admin.flowby.io'}`,
})

plausible.enableAutoPageviews()

try {
  const cookieValue = document.cookie.replace(/(?:(?:^|.*;\s*)klaro\s*=\s*([^;]*).*$)|^.*$/, "$1")
  const decodedKlaroCookie = JSON.parse(decodeURIComponent(cookieValue))
  if (decodedKlaroCookie.posthog) {
    posthog.init(
      process.env.REACT_APP_ENV === 'dev' ?
        'phc_la4epskkakXBtLA1hpxXaJbsmn2QokI1P9AfswPe3WQ' :
        'phc_X94088begmlDh0MpAR7MyFaczTTo9bBXBpBAUyyXSyb',
      { api_host: 'https://eu.posthog.com' }
    )
  }
} catch { /**/ }

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)