export const styles = {
  styles: {
    global: {
      html: {
        height: '100%',
      },
      '#root': {
        height: '100%',
      },
      body: {
        height: '100%',
        color: 'black',
        backgroundColor: '#FEFFFF',
      },
    },
  },
  fonts: {
    heading: 'Montserrat, Verdana',
    body: 'Work Sans, Verdana',
  },
  shadows: {
    outline: 'none',
  },
}

export const components = {
  components: {
    Button: {
      defaultProps: {
        size: 'lg',
      },
      baseStyle: {
        fontFamily: 'Montserrat, Verdana',
        textTransform: 'uppercase',
      },
    },
    FormLabel: {
      baseStyle: {
        fontWeight: 'bold',
        fontSize: 'lg',
      },
    },
    Form: {
      baseStyle: () => ({
        helperText: {
          mt: 2,
          mb: 2,
          color: 'gray.800',
        },
      }),
    },
  },
}

export const colors = {
  colors: {
    cyan: {
      '50': '#EBF5FA',
      '100': '#C7E2F0',
      '200': '#A3CFE6',
      '300': '#7EBCDC',
      '400': '#5AA9D3',
      '500': '#3696C9',
      '600': '#2B78A1',
      '700': '#205A79',
      '800': '#163C50',
      '900': '#0B1E28',
    },
    teal: {
      '50': '#EEF7F5',
      '100': '#CFE8E2',
      '200': '#B0D9D0',
      '300': '#91CABE',
      '400': '#72BBAB',
      '500': '#53AC99',
      '600': '#428A7A',
      '700': '#32675C',
      '800': '#21453D',
      '900': '#11221F',
    },
    purple: {
      '50': '#EBF5FA',
      '100': '#C7E2F0',
      '200': '#A3CFE6',
      '300': '#7EBCDC',
      '400': '#5AA9D3',
      '500': '#3696C9',
      '600': '#2B78A1',
      '700': '#205A79',
      '800': '#163C50',
      '900': '#0B1E28',
    },
    red: {
      '50': '#FBEAEE',
      '100': '#F3C3CF',
      '200': '#EC9DAF',
      '300': '#E47690',
      '400': '#DD5071',
      '500': '#D52A52',
      '600': '#AB2141',
      '700': '#801931',
      '800': '#551121',
      '900': '#2B0810',
    },
    green: {
      '50': '#EEF7F5',
      '100': '#CFE8E2',
      '200': '#B0D9D0',
      '300': '#91CABE',
      '400': '#72BBAB',
      '500': '#53AC99',
      '600': '#428A7A',
      '700': '#32675C',
      '800': '#21453D',
      '900': '#11221F',
    },
    yellow: {
      '50': '#FEF7E7',
      '100': '#FBE9BB',
      '200': '#F9DB90',
      '300': '#F7CD64',
      '400': '#F4BE39',
      '500': '#F2B00D',
      '600': '#C28D0A',
      '700': '#916A08',
      '800': '#614605',
      '900': '#302303',
    },
    gray: {
      '50': '#F0F4F4',
      '100': '#D6E0E0',
      '200': '#BCCDCD',
      '300': '#A2B9B9',
      '400': '#88A5A5',
      '500': '#6D9292',
      '600': '#577575',
      '700': '#425757',
      '800': '#2C3A3A',
      '900': '#161D1D',
    },
    orange: {
      '50': '#FEF7E7',
      '100': '#FBE9BB',
      '200': '#F9DB90',
      '300': '#F7CD64',
      '400': '#F4BE39',
      '500': '#F2B00D',
      '600': '#C28D0A',
      '700': '#916A08',
      '800': '#614605',
      '900': '#302303',
    },
    blue: {
      '50': '#EBF5FA',
      '100': '#C7E2F0',
      '200': '#A3CFE6',
      '300': '#7EBCDC',
      '400': '#5AA9D3',
      '500': '#3696C9',
      '600': '#2B78A1',
      '700': '#205A79',
      '800': '#163C50',
      '900': '#0B1E28',
    },
    pink: {
      '50': '#FBEAEE',
      '100': '#F3C3CF',
      '200': '#EC9DAF',
      '300': '#E47690',
      '400': '#DD5071',
      '500': '#D52A52',
      '600': '#AB2141',
      '700': '#801931',
      '800': '#551121',
      '900': '#2B0810',
    },
  },
}
